export const MODE = {
    VIEW: 'view',
    UPDATE: 'update',
    CREATE: 'create'
}

export const MODE_TITLE = {
    [MODE.VIEW]: "Просмотр",
    [MODE.UPDATE]: "Редактирование",
    [MODE.CREATE]: "Создание",
}