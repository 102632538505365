import cookie, {serialize} from "cookie";
import cookies from "js-cookie";
import {LOGIN_URL} from "config";
import qs from "qs";

export const EXEC_SSR = "ssr"; //рендеринг на сервере
export const EXEC_NO_SSR_SKIP = "no_ssr_skip"; //рендеринг не на сервере, но происходит проход через регидрацию (пропустить)
export const EXEC_NO_SSR = "no_ssr" //рендеринг на клиенте

export const getExecType = (ctx) => {
    return (!!ctx && !!(ctx.req)) ? EXEC_SSR : (typeof window !== "undefined" ? EXEC_NO_SSR : EXEC_NO_SSR_SKIP);
}

export const getAccessToken = (execType, ctx) => {
    if (ctx?.accessToken) return ctx.accessToken;
    if (execType === EXEC_SSR) {
        return cookie.parse(ctx.req.headers?.cookie || "").accessToken
    } else if (execType === EXEC_NO_SSR) {
        return cookies.get('accessToken');
    }
}

export const getRefreshToken = (execType, ctx) => {
    if (execType === EXEC_SSR) {
        return cookie.parse(ctx.req.headers?.cookie || "").refreshToken
    } else if (execType === EXEC_NO_SSR) {
        return cookies.get('refreshToken');
    }
}

export const setCookies = (execType, ctx, _cookies) => {
    if (execType === EXEC_SSR) {
        ctx.res.setHeader('Set-Cookie', _cookies.map(_cookie => serialize(_cookie.name, _cookie.value, _cookie.options)));
    } else if (execType === EXEC_NO_SSR) {
        for (const _cookie of _cookies) {
            cookies.set(_cookie.name, _cookie.value, _cookie.options);
        }
    }
}

export const removeCookies = (execType, ctx, _cookies) => {
    if (execType === EXEC_SSR) {
        ctx.res.setHeader('Set-Cookie', _cookies.map(_cookie => serialize(_cookie, '', {expires: new Date(Date.now() - 10000)})));
    } else if (execType === EXEC_NO_SSR_SKIP) {
        for (const _cookie of _cookies) {
            cookies.remove(_cookie);
        }
    }
}

export const redirectTo = (execType, ctx, link) => {
    if (execType === EXEC_SSR && ctx?.res) {
        ctx?.res.writeHead(302, {Location: link});
        ctx?.res.end();
    } else if (execType === EXEC_NO_SSR) {
        window.location.href = link;
    }
}

export const getRedirectUrl = ctx => {
    return LOGIN_URL + '?' + qs.stringify({url: getFullRoute(ctx), base: getBaseUrl(ctx)});
}
export const getFullRoute = ctx => ctx?.asPath || (window.location.pathname + window.location.search);
export const getBaseUrl = ctx => ctx?.req?.headers?.host || (window.location.host);
