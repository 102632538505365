export const ROUTES = {
    USER_LIST: '/user/list',
    USER_VIEW: '/user/view',
    USER_CREATE: '/user/create',
    COMPANY_LIST: '/company/list',
    COMPANY_VIEW: '/company/view',
    COMPANY_CREATE: '/company/create',
    USER_GROUP_LIST: '/user-group/list',
    USER_GROUP_VIEW: '/user-group/view',
    USER_GROUP_CREATE: '/user-group/create',
    TAG_LIST: '/tag/list',
    TAG_VIEW: '/tag/view',
    TAG_CREATE: '/tag/create',
    NEWS_LIST: '/news/list',
    NEWS_ITEM_VIEW: '/news/view',
    NEWS_ITEM_CREATE: '/news/create',
    ROLE_LIST: '/role/list',
    ROLE_VIEW: '/role/view',
    ROLE_CREATE: '/role/create',
    IMPORT_LIST: '/import/list',
    IMPORT_VIEW: '/import/view',
    IMPORT_CREATE: '/import/create',
    BROADCAST_LIST: '/broadcast/list',
    BROADCAST_VIEW: '/broadcast/view',
    BROADCAST_CREATE: '/broadcast/create',
    FEED_LIST: '/feed/list',
    FEED_VIEW: '/feed/view',
    FEED_CREATE: '/feed/create',
    PUBLICATION_LIST: '/publication/list',
    PUBLICATION_VIEW: '/publication/view',
    PUBLICATION_CREATE: '/publication/create',
    PUBLICATION_UPDATE: '/publication/update',
    SUBSCRIPTION_LIST: '/subscription/list',
    SUBSCRIPTION_VIEW: '/subscription/view',
    SUBSCRIPTION_CREATE: '/subscription/create',
    PLOT_LIST: '/plot/list',
    PLOT_VIEW: '/plot/view',
    PLOT_CREATE: '/plot/create',
};
